//to handle state
const state = {
    msettlement_collection: {
        coin:'PEN',
        total: (0).toFixed(2),
        applied: (0).toFixed(2),
        balance: (0).toFixed(2),
    },
    account_receivable: [],
    charges: [],
}

//to handle state
const getters = {}

//to handle actions
const actions = {

    mLoadAddAccountReceivable(context,account) {
        let detail = context.state.account_receivable;
        let validate = true;
        for (let index = 0; index < detail.length; index++) {
            const element = detail[index];
            if (element.id_module == account.id_module && element.module == account.module) {
                validate = false;
                break;
            }
        }
        if (validate) {
            context.commit('mAddAccountReceivable',account);
            context.dispatch('mLoadTotalAccountReceivable');
        }
        
    },
    mLoadUpdateAccountReceivable(context,index){
        let detail = context.state.account_receivable;
        detail[index].total = detail[index].total == "" ? (0).toFixed(2):parseFloat(detail[index].total).toFixed(2);
        context.dispatch('mLoadTotalAccountReceivable');
    },
    mLoadDeleteAccountReceivable(context,index){
        context.commit('mDeleteAccountReceivable',index);
        context.dispatch('mLoadTotalCharge');
    },

    mLoadTotalAccountReceivable(context){
        let detail = context.state.account_receivable;
        let total = 0;
        for (let index = 0; index < detail.length; index++) {
            const element = detail[index];
            total += parseFloat(element.total);
        }
        total = total.toFixed(2);
        context.commit('mTotalSettlementCollection',total);
        context.dispatch('mLoadTotalCharge');
    },

    mLoadAddCharge(context,payment) {
        let detail = context.state.charges;
        let validate = true;
        for (let index = 0; index < detail.length; index++) {
            const element = detail[index];
            if (element.id_charge == payment.id_charge) {
                validate = false;
                break;
            }
        }
        if (validate) {
            context.commit('mAddCharge',payment);
            context.dispatch('mLoadTotalCharge');
        }
    },

    mLoadTotalCharge(context) {
        let detail = context.state.charges;
        let msettlement_collection = context.state.msettlement_collection;
        let total = 0;
        let data = {
            total: parseFloat(msettlement_collection.total),
            applied:0,
            balance:0,
        };
        for (let index = 0; index < detail.length; index++) {
            const element = detail[index];
            total += parseFloat(element.amount_final);
        }
        data.applied = parseFloat(data.total) + parseFloat(total);
        data.balance = parseFloat(data.total) - parseFloat(total);
        data.applied = parseFloat(data.applied).toFixed(2);
        data.balance = parseFloat(data.balance).toFixed(2);
        context.commit('mUpdateSettlementCollection',data);
        
    },

    
    mLoadDeleteCharge(context,index){
        context.commit('mDeleteCharge',index);
        context.dispatch('mLoadTotalCharge');
    },
    mLoadResetCharge(context){
        context.commit('mResetCharge');
        context.dispatch('mLoadTotalCharge');
    },
    mLoadResetSettlementCollection(context){
        context.commit('mResetSettlementCollection');
        context.dispatch('mLoadTotalCharge');
    },
    mLoadUpdateSettlementChargeCoin(context,coin){
        context.commit('mUpdateSettlementChargeCoin',coin);
        context.dispatch('mLoadTotalCharge');
    },
    
    

}

//to handle mutations
const mutations = {

    mAddAccountReceivable(state, account) {
        state.account_receivable.push(account);
    },
    mDeleteAccountReceivable(state, index) {
        state.account_receivable.splice(index,1);
    },

    mAddCharge(state, payment) {
        state.charges.push(payment);
    },
    mDeleteCharge(state, index) {
        state.charges.splice(index,1);
    },
    mUpdateSettlementCollection(state, data) {
        state.msettlement_collection.applied = data.applied;
        state.msettlement_collection.balance = data.balance;
    },

    mResetCharge(state) {
        state.charges = [];
    },

    mResetSettlementCollection(state) {
        state.account_receivable = [];
        state.charges = [];
        state.msettlement_collection = { coin:'PEN', total: (0).toFixed(2), applied:(0).toFixed(2),  balance:(0).toFixed(2), };
    },

    mTotalSettlementCollection(state, total) {
        state.msettlement_collection.total = total;
        // state.msettlement_collection.balance = total;
    },

    mUpdateSettlementChargeCoin(state,coin) {
        state.msettlement_collection.coin = coin;
    },
    

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}